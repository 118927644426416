@font-face {
  font-family: "Var";
  src: url("/fonts/PretendardVariable.ttf");
  /* styled-components 폰트이슈 
    font 적용 후 랜더링 시 화면 깜빡임 문제해결 
  */
}
@font-face {
  font-family: "Regular";
  src: url("/fonts/Pretendard-Regular.ttf");
  /* styled-components 폰트이슈 
    font 적용 후 랜더링 시 화면 깜빡임 문제해결 
  */
}
@font-face {
  font-family: "NOTO";
  src: url("/fonts/NotoSansKR.ttf");
  /* styled-components 폰트이슈 
    font 적용 후 랜더링 시 화면 깜빡임 문제해결 
  */
}